import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { CountryUtils } from '@nv/react-commons/src/Utils'
import { OC_SERVICE_TYPES } from 'containers/Base/constants'
import { OCForm } from 'components/OCForm'
import { SUPPORTED_SERVICE_LEVELS } from 'containers/DeliveryType/constants'
import _ from 'lodash'
import moment from 'moment'
import { formatHour, getTimezone } from 'utils/time'
import { PICKUP_TYPES } from '../containers/PickupType/constants'
import { intl } from '../../app/containers/IntlGlobalProvider/index'
import { validateEastMalaysiaPostcode } from './validate'
import { MAX_INSURED_VALUES, MAX_NO_OF_CARTONS, MAX_WEIGHTS, VALID_DOCUMENTS_REQURED_VALUE } from './constants'
import { processTimeslotString } from './processTimeslotString'
import { DEFAULT_TIMESLOT_RANGE, TIMESLOT_REGEX } from 'constants/deliveryTimeslots'
import { requestedPieceTrackingNumbersValidator } from './csvMappingFields.helpers'

const { getPostcodeInfo } = CountryUtils

export const transformSize = size => {
  return global.isNaN(size) ? size : OCForm.SIZE_OPTS[parseInt(size)]
}

export const getDeliveryTimeslot = shipperCountry => (deliveryTimeslot, orderCountry) => {
  // default order's delivery timezone to shipper's country's timezone
  // if delivery country is not in NV's list of countries
  const country = orderCountry && COUNTRIES[orderCountry.toUpperCase()] ? orderCountry : shipperCountry
  let hours
  if (deliveryTimeslot.match(TIMESLOT_REGEX)) {
    hours = processTimeslotString(deliveryTimeslot)
  } else {
    hours = deliveryTimeslot.split('-').map(time => parseInt(time.split(':')[0]))
  }
  return {
    startTime: formatHour(hours[0]),
    endTime: formatHour(hours[1]),
    timezone: getTimezone(country)
  }
}

const isOrderB2BBundle = serviceType => {
  const { B2B_BUNDLE, CORPORATE_B2B_BUNDLE } = OC_SERVICE_TYPES
  return serviceType === B2B_BUNDLE || serviceType === CORPORATE_B2B_BUNDLE
}

export const mapBool = value => value && /true/i.test(value)

const mapDeliveryDate = value => moment(value).format('YYYY-MM-DD')
const mapDeliveryTypeToServiceLevel = type => SUPPORTED_SERVICE_LEVELS[(type || '').replace(/\s+/g, '').toUpperCase()]
const mapPackageType = type => {
  return OC_SERVICE_TYPES[_.upperCase(type || '')]
}
const notFalse = value => !/false/i.test(value)
const mapFloat = value => (isNaN(value) ? undefined : parseFloat(value))
const mapInt = value => (isNaN(value) ? undefined : parseInt(value))
const mapString = value => value && value.toString()

// TODO: we should translate the sample data
// TODO [teddy]: the display name should just be the label. And it should be translated. Fix this!
// Fields that can be present in a CSV file
export const makeNinjaPackID = ({ country }) => ({
  key: 'ninja_pack_tracking_id',
  label: 'NINJA PACK ID',
  data: `NIN${country}SMC0ELJGC28`,
  mappedTo: 'requestedTrackingNumber',
  mappingFunction: mapString
})
export const makeCorpAwbID = ({ country }) => ({
  key: 'corp_awb_tracking_id',
  label: 'CORPORATE MANUAL AWB ID',
  data: `NIN${country}SMC0ELJGC28`,
  mappedTo: 'requestedTrackingNumber',
  mappingFunction: mapString
})
export const makeRequestedTrackingNumber = () => ({
  key: 'requested_tracking_number',
  label: 'REQUESTED TRACKING NUMBER',
  data: 'JON-SNOW-1234',
  mappedTo: 'requestedTrackingNumber',
  mappingFunction: mapString
})
export const makeName = () => ({
  key: 'name',
  label: 'NAME',
  data: 'Jon Snow',
  mappedTo: 'to.name',
  mappingFunction: mapString
})
export const makeAddress1 = () => ({
  key: 'address_1',
  label: 'ADDRESS 1',
  data: 'JALAN DART 13/22',
  mappedTo: 'to.address.address1',
  mappingFunction: mapString
})
export const makeAddress2 = () => ({
  key: 'address_2',
  label: 'ADDRESS 2',
  data: 'SEKSYEN 13',
  mappedTo: 'to.address.address2',
  mappingFunction: mapString
})
export const makeNeighborhood = ({ addressInfo: { neighborhood } }) => ({
  key: neighborhood,
  label: neighborhood.toUpperCase(),
  data: '',
  mappedTo: `to.address.${neighborhood}`,
  mappingFunction: mapString
})
export const makeLocality = ({ addressInfo: { locality } }) => ({
  key: locality,
  label: locality.toUpperCase(),
  data: '',
  mappedTo: `to.address.${locality}`,
  mappingFunction: mapString
})
export const makeRegion = ({ addressInfo: { region } }) => ({
  key: region,
  label: region.toUpperCase(),
  data: '',
  mappedTo: `to.address.${region}`,
  mappingFunction: mapString
})
export const makeEmail = () => ({
  key: 'email',
  label: 'EMAIL',
  data: 'jonsnow@thewall.com',
  mappedTo: 'to.email',
  mappingFunction: mapString
})
export const makePhoneNumber = () => ({
  key: 'phone_number',
  label: 'CONTACT',
  data: '990032122',
  mappedTo: 'to.phoneNumber',
  mappingFunction: mapString
})
export const makePostcode = ({ country, serviceType }) => {
  const len = getPostcodeInfo(country)?.length ?? 6
  const dummyData = '123456'
  return {
    key: 'postcode',
    label: 'POSTCODE',
    data: dummyData.slice(0, len),
    mappedTo: 'to.address.postcode',
    mappingFunction: mapString,
    validate: function (value) {
      if (
        validateEastMalaysiaPostcode({ country, isNinjaPackOrder: serviceType === OC_SERVICE_TYPES.NINJA_PACKS, value })
      ) {
        return [
          {
            reason: intl.formatMessage({ id: 'postcode_east_malaysia_restriction_long' }),
            field: `${this.label}: `,
            message: intl.formatMessage({ id: 'postcode_east_malaysia_restriction_long' })
          }
        ]
      }
    }
  }
}
export const makeCountry = ({ country }) => ({
  key: 'country',
  label: 'COUNTRY',
  data: country,
  mappedTo: 'to.address.country',
  defaultValue: country, // Default to the shipper's country,
  mappingFunction: mapString
})
export const makeDeliveryStartDate = ({ rsvn, pickupType }) => ({
  key: 'delivery_start_date',
  label: 'DELIVERY DATE',
  data: '2018-12-19',
  mappedTo: 'parcelJob.deliveryStartDate',
  // For unscheduled, default to today's date + 1 day, otherwise default to last rsvn date + 1 day
  defaultValue:
    pickupType !== PICKUP_TYPES.SCHEDULED
      ? moment()
          .add(1, 'day')
          .format('YYYY-MM-DD')
      : moment(rsvn?.readyDatetime)
          .add(1, 'day')
          .format('YYYY-MM-DD'),
  mappingFunction: mapDeliveryDate
})
export const makeDeliveryTimeslot = ({ country, shouldDisplayDeliveryTimeslot }) => ({
  key: 'delivery_timeslot',
  label: 'DELIVERY TIME SLOT',
  data: '09:00-22:00',
  mappedTo: 'parcelJob.deliveryTimeslot',
  defaultValue: '09:00-22:00',
  mappingFunction: getDeliveryTimeslot(country),
  requiredField: 'delivery_type',
  validate: function (value, requiredFieldValue) {
    if (
      !_.isEmpty(value) &&
      country?.toLowerCase() === COUNTRIES.SG &&
      mapDeliveryTypeToServiceLevel(requiredFieldValue) !== SUPPORTED_SERVICE_LEVELS.NEXTDAY &&
      shouldDisplayDeliveryTimeslot
    ) {
      let hours
      if (value.match(TIMESLOT_REGEX)) {
        hours = processTimeslotString(value)
      } else if (value.includes('-')) {
        hours = value.split('-').map(time => parseInt(time.split(':')[0]))
      }
      if (hours?.[0] !== DEFAULT_TIMESLOT_RANGE[0] || hours?.[1] !== DEFAULT_TIMESLOT_RANGE[1]) {
        const invalidMessage = intl.formatMessage({ id: 'invalid_timeslot_without_nextday_type' })
        return [
          {
            reason: invalidMessage,
            field: `${this.label}: `,
            message: invalidMessage
          }
        ]
      }
    }
  }
})
export const makeSize = () => ({
  key: 'size',
  label: 'SIZE',
  data: 'S',
  defaultValue: 'S',
  mappedTo: 'parcelJob.dimensions.size',
  mappingFunction: transformSize
})
export const makeWeight = ({ country }) => ({
  key: 'weight',
  label: 'WEIGHT',
  data: '1.3',
  mappedTo: 'parcelJob.dimensions.weight',
  mappingFunction: mapFloat,
  validate: function (value) {
    const maxWeight = MAX_WEIGHTS[country?.toLowerCase()]
    const inValidWeightMessage = intl.formatMessage({ id: 'invalid_weight' }, { x: maxWeight })
    if (maxWeight && value > maxWeight) {
      return [
        {
          reason: inValidWeightMessage,
          field: `${this.label}: `,
          message: inValidWeightMessage
        }
      ]
    }
  }
})
export const makePackageType = ({ serviceType }) => ({
  key: 'service_type',
  label: 'PACKAGE TYPE',
  data: serviceType || OC_SERVICE_TYPES.PARCEL,
  defaultValue: serviceType,
  mappedTo: 'serviceType',
  mappingFunction: mapPackageType
})
export const makeDeliveryType = ({ deliveryType }) => ({
  key: 'delivery_type',
  label: 'DELIVERY TYPE',
  data: 'STANDARD',
  mappedTo: 'serviceLevel',
  defaultValue: deliveryType || 'STANDARD',
  mappingFunction: mapDeliveryTypeToServiceLevel
})
export const makeShipperOrderNumber = () => ({
  key: 'shipper_order_number',
  label: 'SHIPPER ORDER NO',
  data: 'HZ-901-12',
  mappedTo: 'reference.merchantOrderNumber',
  mappingFunction: mapString
})
export const makeInstructions = () => ({
  key: 'instructions',
  label: 'INSTRUCTIONS',
  data: 'If recipient is not around, leave parcel in power riser.',
  mappedTo: 'parcelJob.deliveryInstructions',
  mappingFunction: mapString
})
export const makeWeekendDelivery = () => ({
  key: 'weekend_delivery',
  label: 'WEEKEND DELIVERY',
  data: 'True',
  mappedTo: 'parcelJob.allowWeekendDelivery',
  defaultValue: 'True',
  mappingFunction: notFalse
})
export const makeCOD = () => ({
  key: 'cod',
  label: 'CASH ON DELIVERY',
  data: '23',
  mappedTo: 'parcelJob.cashOnDelivery',
  mappingFunction: mapFloat,
  validate: function (value) {
    if (!_.isEmpty(value) && _.isNaN(_.toNumber(value))) {
      return [
        {
          reason: intl.formatMessage({ id: 'invalid_number' }),
          field: `${this.label}: `,
          message: intl.formatMessage({ id: 'invalid_number' })
        }
      ]
    }
  }
})
export const makeInsuredValue = ({ country }) => ({
  key: 'insured_value',
  label: 'INSURED VALUE',
  data: '10',
  mappedTo: 'parcelJob.insuredValue',
  mappingFunction: mapFloat,
  validate: function (value) {
    if (!_.isEmpty(value) && _.isNaN(_.toNumber(value))) {
      return [
        {
          reason: intl.formatMessage({ id: 'invalid_number' }),
          field: `${this.label}: `,
          message: intl.formatMessage({ id: 'invalid_number' })
        }
      ]
    }
    const maxInsuredValue = MAX_INSURED_VALUES[country?.toLowerCase()]
    const inValidInsuredValueMessage = intl.formatMessage({ id: 'invalid_insured_value' }, { x: maxInsuredValue })

    if (maxInsuredValue && value > maxInsuredValue) {
      return [
        {
          reason: inValidInsuredValueMessage,
          field: `${this.label}: `,
          message: inValidInsuredValueMessage
        }
      ]
    }
  }
})
export const makeVolume = () => ({
  key: 'volume',
  label: 'VOLUME',
  data: '0.15',
  mappedTo: 'parcelJob.dimensions.volume',
  mappingFunction: mapFloat
})
export const makeLength = () => ({
  key: 'length',
  label: 'LENGTH',
  data: '0.87',
  mappedTo: 'parcelJob.dimensions.length',
  mappingFunction: mapFloat
})
export const makeWidth = () => ({
  key: 'width',
  label: 'WIDTH',
  data: '0.4',
  mappedTo: 'parcelJob.dimensions.width',
  mappingFunction: mapFloat
})
export const makeHeight = () => ({
  key: 'height',
  label: 'HEIGHT',
  data: '0.43',
  mappedTo: 'parcelJob.dimensions.height',
  mappingFunction: mapFloat
})
export const makeParcelDescription = () => ({
  key: 'parcel_description',
  label: 'PARCEL DESCRIPTION',
  data: 'Test Description',
  mappedTo: 'parcelDescription',
  mappingFunction: mapString
})
export const makeIsDangerousGood = () => ({
  key: 'is_dangerous_good',
  label: 'IS DANGEROUS GOOD',
  data: true,
  mappedTo: 'isDangerousGood'
})
export const makeNoOfCartons = () => ({
  key: 'no_of_cartons',
  label: 'NUMBER OF CARTONS',
  data: '1',
  mappingFunction: mapInt,
  requiredField: 'service_type',
  validate: function (value, serviceType) {
    const isB2BBundleOrder = isOrderB2BBundle(serviceType)
    if (isB2BBundleOrder && _.isEmpty(value)) {
      return [
        {
          reason: intl.formatMessage({ id: 'invalid_value_no_of_cartons' }),
          field: `${this.label}: `,
          message: intl.formatMessage({ id: 'invalid_value_no_of_cartons' })
        }
      ]
    }

    const noOfCartons = parseInt(value)
    if (isB2BBundleOrder && (_.isNaN(_.toNumber(value)) || noOfCartons > MAX_NO_OF_CARTONS || noOfCartons < 1)) {
      return [
        {
          reason: intl.formatMessage({ id: 'invalid_no_of_cartons' }),
          field: `${this.label}: `,
          message: intl.formatMessage({ id: 'invalid_no_of_cartons' })
        }
      ]
    }

    if (!isB2BBundleOrder && !_.isEmpty(value)) {
      return [
        {
          reason: intl.formatMessage({ id: 'invalid_package_type_no_of_cartons' }),
          field: `${this.label}: `,
          message: intl.formatMessage({ id: 'invalid_package_type_no_of_cartons' })
        }
      ]
    }
  }
})
export const makeGrnRequired = () => ({
  key: 'grn_required',
  label: 'GRN REQUIRED?',
  data: 'False',
  requiredField: 'service_type',
  validate: function (value, serviceType) {
    const isB2BBundleOrder = isOrderB2BBundle(serviceType)

    if (isB2BBundleOrder && !_.isEmpty(value)) {
      const grn = value.toLowerCase().trim()
      if (!VALID_DOCUMENTS_REQURED_VALUE.includes(grn)) {
        return [
          {
            reason: intl.formatMessage({ id: 'invalid_grn' }),
            field: `${this.label}: `,
            message: intl.formatMessage({ id: 'invalid_grn' })
          }
        ]
      }
    }

    if (!isB2BBundleOrder && !_.isEmpty(value)) {
      const grn = value.toLowerCase().trim()
      if (grn !== 'false') {
        return [
          {
            reason: intl.formatMessage({ id: 'invalid_package_type_rdo_grn' }),
            field: `${this.label}: `,
            message: intl.formatMessage({ id: 'invalid_package_type_rdo_grn' })
          }
        ]
      }
    }
  }
})
export const makeRdoRequired = () => ({
  key: 'rdo_required',
  label: 'RDO REQUIRED?',
  data: 'False',
  requiredField: 'service_type',
  validate: function (value, serviceType) {
    const isB2BBundleOrder = isOrderB2BBundle(serviceType)

    if (isB2BBundleOrder && !_.isEmpty(value)) {
      const rdo = value.toLowerCase().trim()
      if (!VALID_DOCUMENTS_REQURED_VALUE.includes(rdo)) {
        return [
          {
            reason: intl.formatMessage({ id: 'invalid_rdo' }),
            field: `${this.label}: `,
            message: intl.formatMessage({ id: 'invalid_rdo' })
          }
        ]
      }
    }

    if (!isB2BBundleOrder && !_.isEmpty(value)) {
      const rdo = value.toLowerCase().trim()
      if (rdo !== 'false') {
        return [
          {
            reason: intl.formatMessage({ id: 'invalid_package_type_rdo_grn' }),
            field: `${this.label}: `,
            message: intl.formatMessage({ id: 'invalid_package_type_rdo_grn' })
          }
        ]
      }
    }
  }
})
export const makeRequestedPieceTrackingNumbers = () => ({
  key: 'requested_piece_tracking_numbers',
  label: 'REQUESTED PIECE TRACKING NUMBERS',
  data: 'PIECE123',
  requiredField: 'no_of_cartons',
  mappingFunction: mapString,
  validate: function (value, requiredFieldValue) {
    const errMsg = requestedPieceTrackingNumbersValidator(value, requiredFieldValue)
    if (errMsg !== null) {
      return [
        {
          reason: intl.formatMessage({ id: errMsg }),
          field: `${this.label}: `,
          message: intl.formatMessage({ id: errMsg })
        }
      ]
    }
  }
})

// EVERYTHING BELOW IS INTERNATIONAL
export const makePortation = () => ({
  key: 'portation',
  label: 'INTERNATIONAL PORTATION',
  data: 'Import',
  mappedTo: 'international.portation',
  mappingFunction: mapString
})
export const makeCustomsDescription = () => ({
  key: 'customs_description',
  label: 'CUSTOMS DESCRIPTION',
  data: '',
  mappedTo: 'experimentalCustomsDeclaration.customsDescription',
  mappingFunction: mapString
})
export const makeGoodsCurrency = () => ({
  key: 'goods_currency',
  label: 'GOODS CURRENCY',
  data: 'SGD',
  mappedTo: 'experimentalCustomsDeclaration.goodsCurrency',
  mappingFunction: mapString
})
export const makeGoodsValue = () => ({
  key: 'goods_value',
  label: 'GOODS VALUE',
  data: '',
  mappedTo: 'experimentalCustomsDeclaration.goodsValue',
  mappingFunction: mapString
})
export const makeConsigneeCompany = () => ({
  key: 'consignee_company',
  label: 'CONSIGNEE COMPANY',
  data: '',
  mappedTo: 'experimentalCustomsDeclaration.consigneeCompany',
  mappingFunction: mapString
})
export const makeBatteryType = () => ({
  key: 'battery_type',
  label: 'BATTERY TYPE',
  data: '',
  mappedTo: 'experimentalCustomsDeclaration.batteryType',
  mappingFunction: mapString
})
export const makeBatteryPackaging = () => ({
  key: 'battery_packaging',
  label: 'BATTERY PACKAGING',
  data: '',
  mappedTo: 'experimentalCustomsDeclaration.batteryPackaging',
  mappingFunction: mapString
})
export const makeHsCode = () => ({
  key: 'hs_code',
  label: 'HS CODE',
  data: '',
  mappedTo: 'experimentalCustomsDeclaration.hsCode',
  mappingFunction: mapString
})
export const makeMadeInCountry = () => ({
  key: 'made_in_country',
  label: 'MADE IN COUNTRY',
  data: '',
  mappedTo: 'experimentalCustomsDeclaration.madeInCountry',
  mappingFunction: mapString
})
export const makeTradeTerms = () => ({
  key: 'trade_terms',
  label: 'TRADE TERMS',
  data: '',
  mappedTo: 'experimentalCustomsDeclaration.tradeTerms',
  mappingFunction: mapString
})
export const makeLockerPickup = () => ({
  key: 'locker_pickup',
  label: 'LOCKER PICK UP',
  data: '',
  mappedTo: 'experimentalCustomsDeclaration.lockerPickup',
  mappingFunction: mapString
})
export const makeIsGstIncludedInGoodsValue = () => ({
  key: 'is_gst_included_in_goods_value',
  label: 'IS GST INCLUDED IN GOODS VALUE',
  data: 'False',
  mappedTo: 'experimentalCustomsDeclaration.isGstIncludedInGoodsValue',
  mappingFunction: mapBool,
  requiredField: 'gst_registration_number',
  validate: function (value, requiredFieldValue) {
    if (mapBool(value) && !requiredFieldValue) {
      return [
        {
          reason: `${intl.formatMessage({ id: this.requiredField })} ${intl.formatMessage({ id: 'is_required' })}`,
          field: `${this.label}: `,
          message: `${intl.formatMessage({ id: this.requiredField })} ${intl.formatMessage({ id: 'is_required' })}`
        }
      ]
    }
  }
})
export const makeGstRegistrationNumber = () => ({
  key: 'gst_registration_number',
  label: 'GST REGISTRATION NUMBER',
  data: '',
  mappedTo: 'experimentalCustomsDeclaration.gstRegistrationNumber',
  mappingFunction: mapString
})

// prettier-ignore
export const getAllMakeFields = (addressInfo, orderSource, country, isB2BBundleSupported) => {
  const allFields = [
    makeNinjaPackID, makeCorpAwbID, makeRequestedTrackingNumber, makeName,
    makeAddress1, makeAddress2, makeEmail, makePhoneNumber,
    makePostcode, makeCountry, makeDeliveryStartDate, makeDeliveryTimeslot, makeSize, makeWeight, makeDeliveryType,
    makeShipperOrderNumber, makeInstructions, makeWeekendDelivery, makeCOD, makeInsuredValue, makeVolume, makeLength,
    makeWidth, makeHeight, makePortation, makeCustomsDescription, makeGoodsCurrency, makeGoodsValue,
    makeConsigneeCompany, makeBatteryType, makeBatteryPackaging, makeHsCode, makeMadeInCountry, makeTradeTerms,
    makeLockerPickup, makePackageType, makeIsGstIncludedInGoodsValue, makeGstRegistrationNumber
  ]
  if (isB2BBundleSupported) {
    allFields.push(...[makeNoOfCartons, makeGrnRequired, makeRdoRequired, makeRequestedPieceTrackingNumbers  ])
  }
  if (!_.isEmpty(addressInfo)) {
    allFields.push(...[makeNeighborhood, makeLocality, makeRegion])
  }
  return allFields
}

export const getAllPricingMakeFields = (addressInfo, isIntl) => {
  const allFields = [
    makeAddress1,
    makeAddress2,
    makePostcode,
    makeCountry,
    makeDeliveryTimeslot,
    makeSize,
    makeWidth,
    makeHeight,
    makeLength,
    makeWeight,
    makeDeliveryType,
    makeCOD,
    makeInsuredValue
  ]
  if (!_.isEmpty(addressInfo)) {
    allFields.push(...[makeNeighborhood, makeLocality, makeRegion])
  }
  if (isIntl) {
    allFields.push(makePortation)
  }
  return allFields
}
