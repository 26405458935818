import { NvApi } from '@nv/react-commons/src/Services'
import { formatQueryString } from 'utils/queryString'
import { apiResponseTransformer, getRecaptchaRunner } from '@nv/react-commons/src/Recaptcha'
import { CountryUtils } from '@nv/react-commons/src/Utils'
import { getConfig } from 'configs/recaptchaConfig'
import { LOCALES } from '@nv/react-commons/src/Constants/Country'
import { createApi, getDomainSuffix } from 'services/utils'
import { MAP_SCHEDULES_REPORT_TYPE } from 'containers/ReportsV3/ReportsV3.consts'
import { REPORT_TYPE } from 'containers/ReportsV3/ReportsV3.enums'
import { toSnakeCase } from 'utils/customConvertCase'

const dashApi = createApi({ country: 'global', service: 'dash', timeout: 30000 })
dashApi.addResponseTransform(apiResponseTransformer)
dashApi.addResponseTransform(handleRateLimitTransformer)

function handleRateLimitTransformer (resp) {
  if (resp.status === 429) {
    resp.data.error = { code: 150011 }
  }
}

const dashApiNoTransform = createApi({
  country: 'global',
  service: 'dash',
  timeout: 60000,
  transform: false,
  protect: true
})

const dashApiWithreCaptchaBase = {
  post: (url, data) => {
    const reCaptcha = getRecaptchaRunner(getConfig(CountryUtils.getLocale(data?.country || data?.systemId)))
    const doRequest = token => {
      const config = {
        headers: {
          'x-nv-digest': token
        }
      }
      return dashApi.post(url, data, config)
    }
    return reCaptcha.run(doRequest, 'PublicOrders')
  }
}

export default {
  axiosInstance: dashApi.axiosInstance,
  axiosInstanceNoTransform: dashApiNoTransform.axiosInstance,
  // users
  addShipper: data => {
    const type = data.code ? 'code' : 'password'
    return dashApi.post(`1.0/users/shippers?verification_type=${type}`, data)
  },
  auth: data => dashApi.post('1.0/users/login?authentication=true', data),
  forgotPassword: data => dashApi.post('1.0/users/verification-email', data),
  verifyUsername: (data, method) => dashApiWithreCaptchaBase.post(`1.1/users/verify-username?method=${method}`, data),
  addPhoneNumberIdentity: (userId, data) => dashApi.post(`/1.0/users/${userId}/identities?method=phone`, data),
  validateOtp: data => dashApi.post('1.0/users/validate-otp', data),
  getUser: data => dashApi.get('1.0/users', data),
  deleteUser: () => dashApi.delete('1.0/users'),
  signUp: data => {
    const reCaptcha = getRecaptchaRunner(getConfig(CountryUtils.getLocale(data?.country)))
    const doRequest = token => {
      const config = {
        headers: {
          'x-nv-digest': token
        }
      }
      return dashApi.post('1.1/users/register', data, config)
    }
    return reCaptcha.run(doRequest, 'PublicOrders')
  },
  signUpPro: data => {
    const reCaptcha = getRecaptchaRunner(getConfig(CountryUtils.getLocale(data?.country)))
    const doRequest = token => {
      const config = {
        headers: {
          'x-nv-digest': token
        }
      }
      return dashApi.post('1.1/users/register-pro', data, config)
    }
    return reCaptcha.run(doRequest, 'PublicOrders')
  },
  login: (data, options) => {
    const reCaptcha = getRecaptchaRunner(getConfig(LOCALES.SG))
    const doRequest = token => {
      const config = {
        ...(options || {}),
        headers: {
          ...(options?.headers ?? {}),
          'x-nv-digest': token
        }
      }
      return dashApi.post('1.1/users/login', data, config)
    }
    return reCaptcha.run(doRequest, 'PublicOrders')
  },
  loginV2: data => {
    if (getDomainSuffix(window.location.host) === 'cn') {
      return dashApi.post(`2.0/users/login?method=${data.email ? 'email' : 'phone'}`, data)
    }

    const reCaptcha = getRecaptchaRunner(getConfig(LOCALES.SG))
    const doRequest = token => {
      const config = {
        headers: {
          'x-nv-digest': token
        }
      }
      return dashApi.post(`2.0/users/login?method=${data.email ? 'email' : 'phone'}`, data, config)
    }
    return reCaptcha.run(doRequest, 'PublicOrders')
  },
  registerV2: data => {
    const reCaptcha = getRecaptchaRunner(getConfig(LOCALES.SG))
    const doRequest = token => {
      const config = {
        headers: {
          'x-nv-digest': token
        }
      }
      return dashApi.post(`2.0/users/register?method=${data.email ? 'email' : 'phone'}`, data, config)
    }
    return reCaptcha.run(doRequest, 'PublicOrders')
  },
  setup: data => dashApi.put('1.0/users/setup', data),
  resetPassword: data => dashApi.put('1.0/users/reset-password', data),
  verifyEmailAddress: data => dashApi.post('1.0/users/verify-email-address', data),
  confirmLinkAccount: data => dashApi.post('1.0/users/confirm-link-account', data),
  updateUser: data => dashApi.patch('1.0/users', data),
  linkShippers: data => dashApi.post('1.0/users/link-shippers', data),
  sendOtpForlogin: data => dashApiWithreCaptchaBase.post('1.1/users/otp', data),
  sendOtp: (purpose, phoneNumber) =>
    dashApi.get(`1.0/users/get-verification-code?purpose=${purpose}&phone_number=${encodeURIComponent(phoneNumber)}`),
  verifyOtpCode: data => dashApi.post('1.0/users/verify-phone-number', data),
  oAuthSelectShipper: data => dashApi.post('1.0/users/oauth/select-shipper', data),

  // dashboard
  getDashboard: data => dashApi.post('1.0/reports/aggregate-stats', data),

  // orders
  // No transform is used to ensure custom handling of the 'b2b' property, which was added
  // as per ticket COREV2-1933. This custom transformation keeps 'b2b' intact while converting
  // other keys to snake case.
  createOrder: data => dashApiNoTransform.post('1.0/orders', toSnakeCase(data), { timeout: 30000 }),
  cancelOrder: orderId => dashApi.put(`1.0/orders/cancel/${orderId}`),
  editOrder: (orderId, data) => dashApi.patch(`1.0/orders/${orderId}`, data),
  estimatePrice: data => dashApi.post('1.0/orders/estimate-price', data),
  getOrder: (locale, { orderId }) => dashApi.get(`1.0/orders/${orderId}`),
  getOrderWithShipper: (orderId, shipperId) =>
    dashApi.get(`1.0/orders/${orderId}?details=false`, null, {
      headers: { [NvApi.SHIPPER_HEADER]: shipperId }
    }),
  getPod: orderId => dashApi.get(`1.0/orders/${orderId}/pods`),
  getProofs: orderId => dashApi.get(`1.0/orders/${orderId}/proofs`),
  rescheduleOrder: (orderId, data) => dashApi.post(`1.0/orders/${orderId}/reschedule`, data),
  rtsOrder: (orderId, data) => dashApi.put(`1.0/orders/${orderId}/rts`, data),
  searchOrder: (locale, data, from = 0, size = 100, subshippers = true) =>
    dashApi.post(`1.0/orders/search?from=${from}&size=${size}&subshippers=${subshippers}`, data),
  searchOrderWithDetails: (locale, data, from = 0, size = 100) =>
    dashApi.post(`1.0/orders/searchDetail?from=${from}&size=${size}`, data),
  getPricingInfo: orderId => dashApi.get(`1.0/orders/${orderId}/pricing`),

  // shippers
  createAddress: data => dashApi.post('2.0/shippers/addresses', data),
  createClientCredentials: () => dashApi.post('1.0/shippers/client-credentials'),
  createWebhook: data => dashApi.post('1.0/shippers/event-subscriptions/webhook', data),
  deleteAddress: id => dashApi.delete(`1.0/shippers/addresses/${id}`),
  deleteWebhook: id => dashApi.delete(`1.0/shippers/event-subscriptions/${id}`),
  getAddresses: () => dashApi.get('2.0/shippers/addresses'),
  getClientCredentials: () => dashApi.get('1.0/shippers/client-credentials'),
  getMilkRunSettings: addressId => dashApi.get(`1.0/shippers/addresses/${addressId}/milkrun`),
  getSettings: (namespace, shipperId) =>
    dashApi.get(`1.0/shippers/settings?namespace=${namespace}`, null, {
      ...(shipperId && { headers: { [NvApi.SHIPPER_HEADER]: shipperId } })
    }),
  getMasterSettings: namespace => dashApi.get(`1.0/shippers/master/settings?namespace=${namespace}`),
  getWebhooks: () => dashApi.get('1.0/shippers/event-subscriptions/webhook'),
  getMetadata: () => dashApi.get(`1.0/shippers/metadata`),
  getSubShippers: shipperId =>
    dashApi.get('1.0/shippers/sub-shippers', null, {
      ...(shipperId && { headers: { [NvApi.SHIPPER_HEADER]: shipperId } })
    }),
  getShipperBranch: (corporateId, shipperId) =>
    dashApi.get(`1.0/shippers/corporate/${corporateId}/branch`, null, {
      ...(shipperId && { headers: { [NvApi.SHIPPER_HEADER]: shipperId } })
    }),
  getShippersCount: masterId =>
    dashApi.get('1.0/shippers/count', null, {
      ...(masterId && { headers: { [NvApi.SHIPPER_HEADER]: masterId } })
    }),
  updateAddress: (data, id) => dashApi.put(`1.0/shippers/addresses/${id}`, data),
  updateSettings: (namespace, data) => dashApi.patch(`1.0/shippers/settings?namespace=${namespace}`, data),
  updateShipper: data => dashApi.patch('1.0/shippers', data),
  updateWebhook: (id, data) => dashApi.put(`1.0/shippers/event-subscriptions/webhook/${id}`, data),

  // wallet
  createTopup: data => dashApi.post('1.0/payments/top-ups', data),

  // reservations
  createReservation: data => dashApi.post('1.0/reservations', data),
  deleteReservation: rsvnId => dashApi.delete(`1.0/reservations/${rsvnId}`),
  getPods: rsvnId => dashApi.get(`1.0/reservations/${rsvnId}/pods`),
  getReservations: data => dashApi.get(`1.0/reservations${formatQueryString(data)}`),
  updateReservation: (rsvnId, data) => dashApi.put(`1.0/reservations/${rsvnId}`, data),

  // pickup appointments
  createPickupAppointment: data => dashApi.post('1.0/pickup-appointments', data),
  deletePickupAppointment: paId => dashApi.delete(`1.0/pickup-appointments/${paId}`),
  updatePickupAppointment: (paId, data) => dashApi.patch(`1.0/pickup-appointments/${paId}`, data),
  getPickupAppointments: data => dashApi.get(`1.0/pickup-appointments/search${formatQueryString(data)}`),

  // csv-mappings
  createMapping: data => dashApi.post('1.0/csv-mappings', data),
  deleteMapping: id => dashApi.delete(`1.0/csv-mappings/${id}`),
  getMapping: id => dashApi.get(`1.0/csv-mappings/${id}`),
  getMappings: () => dashApi.get('1.0/csv-mappings'),
  updateMapping: (id, data) => dashApi.put(`1.0/csv-mappings/${id}`, data),

  // order batches
  createBatchErrors: (batchId, data) => dashApi.put(`1.0/batches/${batchId}/errors`, data),
  deleteBatch: id => dashApi.delete(`1.0/batches/${id}`),
  cancelBatch: batchId => dashApi.put(`1.0/batches/cancel/${batchId}`),
  estimateBatchPrice: data => dashApi.post('2.0/orders/estimate-price', data),
  getBatchErrors: batchId => dashApi.get(`1.0/batches/${batchId}/errors`),
  getBatches: () => dashApi.get('1.0/batches'),
  getBatchStatus: batchId => dashApi.get(`1.0/batches/${batchId}`),
  getFailedOrders: ({ batchId, page = 1, pageSize = 100 }) =>
    dashApi.get(`1.0/batches/${batchId}/failed-orders`, { page, page_size: pageSize }),
  completeBatch: batchId => dashApi.patch(`1.0/batches/${batchId}/complete`),

  // reports
  getDeliveryStatusReports: data =>
    dashApi.post(
      '2.0/reports/generate',
      { reportType: MAP_SCHEDULES_REPORT_TYPE[REPORT_TYPE.deliveryStatus], reportFormat: 'xlsx', ...data },
      { timeout: 30000 }
    ),
  getDeliveryFailureReports: data =>
    dashApi.post(
      '2.0/reports/generate',
      {
        reportType:
          MAP_SCHEDULES_REPORT_TYPE[data.trackingIds ? REPORT_TYPE.deliveryFailureByCSV : REPORT_TYPE.deliveryFailure],
        reportFormat: 'csv',
        ...data
      },
      { timeout: 30000 }
    ),

  getDeliveryStatusMasterReports: data =>
    dashApi.post(
      '2.0/reports/master/generate',
      { reportType: MAP_SCHEDULES_REPORT_TYPE[REPORT_TYPE.deliveryStatus], reportFormat: 'xlsx', ...data },
      { timeout: 30000 }
    ),
  getDeliveryFailureMasterReports: data =>
    dashApi.post(
      '2.0/reports/master/generate',
      { reportType: MAP_SCHEDULES_REPORT_TYPE[REPORT_TYPE.deliveryFailure], reportFormat: 'csv', ...data },
      { timeout: 30000 }
    ),

  // accounts
  getAccountSettings: namespace => dashApi.get('1.0/accounts/settings', { namespace }),
  updateAccountSettings: (namespace, settings) => dashApi.put(`1.0/accounts/settings?namespace=${namespace}`, settings),

  // message
  getMessages: () => dashApi.get('1.0/shippers/messages'),
  sendMessageAck: ack => dashApi.post('1.0/shippers/messages', ack),

  // loyalty
  getLoyalty: () => dashApi.get('/1.0/loyalty'),
  getLoyaltyInfo: () => dashApi.get('/1.0/loyalty/info'),

  // Account linking
  getLinkableShippers: () => dashApi.get('/1.0/shippers/linkable'),
  unlinkShippers: data => dashApi.post('1.0/users/unlink-shippers', data),

  // Marketplace Seller
  getMarketplaceSeller: (shipperId, sellerExternalRef) =>
    dashApi.get(`2.0/shippers/${shipperId}/sellers?external_ref=${encodeURIComponent(sellerExternalRef)}`, null, {
      ...(shipperId && { headers: { [NvApi.SHIPPER_HEADER]: shipperId } })
    }),

  // Corporate Branch
  getCorporateBranch: (corporateId, branchExternalRef) =>
    dashApi.get(`1.0/shippers/corporate/${corporateId}/branch/external-ref/${branchExternalRef}`),

  getOverallBalance: ({ prepaid }) => dashApi.get(`/1.0/cod_reconciliation/overall-balance?prepaid=${prepaid}`),

  getSpecificBalance: ({ startDate, endDate }) =>
    dashApi.get(`1.0/cod_reconciliation/balance?start_date=${startDate}&end_date=${endDate}`),

  getRemittanceHistory: ({ startDate, endDate, start, limit }) =>
    dashApi.get(
      `/1.0/cod_reconciliation/history?start_date=${startDate}&end_date=${endDate}&start=${start}&limit=${limit}`
    ),
  getCODReconciliationReport: data => dashApi.post('/1.0/billing/reports/cod-reconciliation', { ...data }),
  sendInvoice: data => dashApi.post('/1.0/billing/reports/financial-batch-orders', { ...data }),

  // Campaigns
  getCampaigns: () => dashApi.get('1.0/campaigns')
}

/* eslint camelcase:0 */
